<template>
  <Tabs v-model="tabsName" :animated="false" @on-click="changetabBox" class="tabs_styles">
    <!--箱/袋管理-->
    <TabPane :label="$t('key1005852')" name="packingManage">
      <packingList v-if="tabsName === 'packingManage'" @changeTabs="changeTabs"></packingList>
    </TabPane>
    <!--扫描装箱-->
    <TabPane :label="$t('key1005853')" name="scanPacking">
      <scanPacking v-if="tabsName === 'scanPacking'" :pickupOrderNumber="pickupOrderNumber" @changeTabs="changeTabs" :type="type"></scanPacking>
    </TabPane>
    <!--待装箱列表-->
    <TabPane :label="$t('key1001271')" name="exWarehouse">
      <exWarehouse v-if="tabsName === 'exWarehouse'"></exWarehouse>
    </TabPane>
  </Tabs>
</template>
<script>
import packingList from './packingList';
import scanPacking from './scanPacking';
import exWarehouse from "@/views/supplier/components/wms/exWarehouse/exwarehouse";

export default {
  name: 'packingManage',
  data() {
    return {
      tabsName: 'packingManage',
      pickupOrderNumber: null,
      type: 'scanning' // continue: 继续装箱， scanning：扫描装箱，adding：新增
    };
  },
  created() {
    if (this.$route.params.tabsName) {
      this.tabsName = 'exWarehouse';
    }
  },
  methods: {
    // 切换tabs 栏时，要重置数据
    changetabBox(value) {
      if (value === 'packingManage') {
        this.pickupOrderNumber = null;
        this.type = 'scanning';
      }
    },
    // 切换到扫描、新增箱\袋、继续装箱
    changeTabs(data) {
      this.tabsName = data.value;
      if (data.pickupOrderNumber) {
        this.pickupOrderNumber = data.pickupOrderNumber;
      }
      this.type = data.type;
    }
  },
  components: {
    packingList,
    scanPacking,
    exWarehouse
  }
};
</script>

<style lang="less" scoped>
.tabs_styles {
  /deep/ .ivu-tabs-bar {
    border-bottom: none;
    background-color: #fff;
    padding: 8px 0 3px 0;
    margin: 0;
  }
}
</style>
